import React from "react";
import { Link } from "gatsby";

import logoFooter from "../img/logo-footer-resized.png";
import facebook from "../img/social/facebook.svg";
import linkedIn from "../img/social/linked-in.svg";
import twitter from "../img/social/twitter.svg";
import ciepLogo from "../img/CIEP-PM-logo-online-white-cropped.png";

const Footer = class extends React.Component {
    render() {
        return (
            <footer className="footer has-background-warning has-text-white-ter">
                <div className="content has-text-centered">
                    <img src={logoFooter} alt="Read Panda logo" style={{ height: "10em" }} />
                </div>
                <div className="content has-text-centered has-background-warning has-text-white-ter">
                    <div id="footer_links-container" className="has-background-warning has-text-white-ter">
                        <div style={{ maxWidth: "100vw" }}>
                            <div>
                                <section
                                    className="menu"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <ul id="footer_links-container_links" className="menu-list">
                                        <li>
                                            <Link to="/" className="navbar-item">
                                                Home
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="navbar-item" to="/services">
                                                Services
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="navbar-item" to="/about">
                                                About
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="navbar-item" to="/blog">
                                                Blog
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="navbar-item" to="/contact">
                                                Contact us
                                            </Link>
                                        </li>
                                    </ul>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="social has-text-centered">
                    <a
                        title="Facebook"
                        href="https://www.facebook.com/readpandaediting"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={facebook} alt="Facebook" style={{ width: "1em", height: "1em" }} />
                    </a>
                    <a
                        title="Twitter"
                        href="https://twitter.com/readpandaedit"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            className="fas fa-lg"
                            src={twitter}
                            alt="Twitter"
                            style={{ width: "1em", height: "1em" }}
                        />
                    </a>
                    <a
                        title="LinkedIn"
                        href="https://www.linkedin.com/company/readpanda"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={linkedIn} alt="Linked in" style={{ width: "1em", height: "1em" }} />
                    </a>
                </div>
            </footer>
        );
    }
};

export default Footer;
