import React from "react";
import { Link } from "gatsby";
import github from "../img/github-icon.svg";
import logoNavbar from "../img/logo-navbar.png";

const Navbar = class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            navBarActiveClass: "",
        };
    }

    toggleHamburger = () => {
        // toggle the active boolean in the state
        this.setState(
            {
                active: !this.state.active,
            },
            // after state has been updated,
            () => {
                // set the class in state for the navbar accordingly
                this.state.active
                    ? this.setState({
                          navBarActiveClass: "is-active",
                      })
                    : this.setState({
                          navBarActiveClass: "",
                      });
            }
        );
    };

    render() {
        return (
            <nav
                className="navbar has-text-black has-text-weight-bold"
                role="navigation"
                aria-label="main-navigation"
            >
                <div className="container">
                    <div className="navbar-brand">
                        <Link to="/" className="navbar-item" title="Logo">
                            <img src={logoNavbar} alt="Read Panda" style={{ height: "60%" }} />
                        </Link>
                        {/* Hamburger menu */}
                        <div
                            className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                            data-target="navMenu"
                            onClick={() => this.toggleHamburger()}
                        >
                            <span />
                            <span />
                            <span />
                        </div>
                    </div>
                    <div id="navMenu" className={`navbar-menu is-size-5 ${this.state.navBarActiveClass}`}>
                        <div className="navbar-start has-text-centered">
                            <Link
                                className="navbar-item has-text-white"
                                style={{ padding: "1.5rem 1rem" }}
                                to="/services"
                            >
                                Services
                            </Link>
                            <Link
                                className="navbar-item has-text-white"
                                style={{ padding: "1.5rem 1rem" }}
                                to="/about"
                            >
                                About
                            </Link>
                            <Link
                                className="navbar-item has-text-white"
                                style={{ padding: "1.5rem 1rem" }}
                                to="/blog"
                            >
                                Blog
                            </Link>
                            <Link
                                className="navbar-item has-text-white"
                                style={{ padding: "1.5rem 1rem" }}
                                to="/contact"
                            >
                                Contact us
                            </Link>
                        </div>
                        {/* <div className="navbar-end has-text-centered">
							<a
								className="navbar-item"
								href="https://github.com/netlify-templates/gatsby-starter-netlify-cms"
								target="_blank"
								rel="noopener noreferrer"
							>
								<span className="icon">
									<img src={github} alt="Github" />
								</span>
							</a>
						</div> */}
                    </div>
                </div>
            </nav>
        );
    }
};

export default Navbar;
